import { useState, ChangeEvent } from "react";
interface UseForm<T> {
  initialState: T;
}
export default function useForm<T>({ initialState }: UseForm<T>) {
  const [formState, setFormState] = useState<T>(initialState);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const resetForm = () => {
    setFormState(initialState);
  };

  return { formState, handleChange, resetForm };
}
