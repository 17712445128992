import React from "react";
import { scroller } from "react-scroll";
import telephoneImg from "./images/telephone.png";
import noteImg from "./images/note.png";
import arrorwUpFilled from "./images/arrow-up-filled.svg";
import VerifiedIcon from "../atoms/icons/VerifiedIcon";
import {
  SubHeadlineWrapper,
  SubHeadlineMedia,
  SubHeadlineImageContainer,
  SubHeadlineArea,
  SubHeadlineButton,
} from "./styles/StyleSubHeadline";
function SubHeadline() {
  const handleScrollToForm = () => {
    scroller.scrollTo("form_section", {
      duration: 1500,
      deelay: 100,
      smooth: true,
      offset: -50,
    });
  };
  return (
    <SubHeadlineWrapper id="consultant">
      <div className="subheadline_arrow_up">
        <img src={arrorwUpFilled} alt="arrow up" />
      </div>
      <SubHeadlineArea>
        <h4>
          Предоставляем <span>скидку</span> <br /> на все наши автомобили
        </h4>
        <p>
          Оставьте заявку на консультацию <br /> и получите индивидуальную
          скидку
        </p>
        <SubHeadlineButton onClick={handleScrollToForm}>
          Получить оценку вашего авто
        </SubHeadlineButton>
      </SubHeadlineArea>

      <SubHeadlineMedia>
        <SubHeadlineImageContainer>
          <VerifiedIcon className="left_verified_logo" />
          <img
            className="telephone_logo"
            src={telephoneImg}
            alt="telepone img"
          />
        </SubHeadlineImageContainer>
        <p>Предоставляем онлайн консультацию 24/7</p>
      </SubHeadlineMedia>

      <SubHeadlineMedia>
        <SubHeadlineImageContainer>
          <VerifiedIcon className="right_verified_logo" />
          <img className="note_logo" src={noteImg} alt="note img" />
        </SubHeadlineImageContainer>
        <p>Юридически правильно составим документы для проведения сделки</p>
      </SubHeadlineMedia>
    </SubHeadlineWrapper>
  );
}

export default SubHeadline;
