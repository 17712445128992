interface IDevices {
  mobile: string;
  tablet: string;
  tabletLarge: string;
  laptop: string;
  large: string;
}
export const devices: IDevices = {
  mobile: `(max-width:481px)`,
  tablet: `(min-width:481px) and (max-width:769px)`,
  tabletLarge: `(min-width:769px) and (max-width:1024px)`,
  laptop: `(min-width: 1025px) and (max-width:1200px)`,
  large: `(min-width:1201px) and (max-width: 1500px)`,
};
