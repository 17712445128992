import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

const SharedStyles = styled.div`
  display: flex;
  align-items: center;
`;

export const StyleNavigationContainer = styled(SharedStyles)`
  gap: 2em;
  height: 50px;
  width: 100%;
  @media only screen and ${devices.mobile} {
    flex-wrap: wrap;
    gap: 0.2em;
    & .break_to_new_row {
      flex-basis: 100%;
      height: 0;
    }
  }
  @media only screen and ${devices.tablet} {
    flex-wrap: wrap;
    gap: 0.2em;
    & .break_to_new_row {
      flex-basis: 100%;
      height: 0;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    flex-wrap: wrap;
    gap: 0.3em;
    & .break_to_new_row {
      flex-basis: 100%;
      height: 0;
    }
  }
  @media only screen and ${devices.laptop}{
    gap:1.2em;
  }
`;

export const StyleNavigationLogo = styled.div``;

export const StyleNavigationTextWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 24px;
  color: #a46a6a;
  width: 152px;
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
`;

export const StyleNavigationMenuWrapper = styled(SharedStyles)`
  justify-content: center;
  gap: 0.5em;
  background: linear-gradient(263.19deg, #962727 0%, #451919 99.21%);
  border: 1px solid #bd5d5d;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 24px;
  color: #ffffff;
  padding: 0.3em;
  cursor: pointer;
  @media only screen and ${devices.mobile} {
    background: none;
    border: none;
    margin-left: auto;
    svg {
      height: 45px;
      width: 45px;
    }
    span {
      display: none;
    }
  }
  @media only screen and ${devices.tablet} {
    background: none;
    border: none;
    margin-left: auto;
    svg {
      height: 45px;
      width: 45px;
    }
    span {
      display: none;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    background: none;
    border: none;
    margin-left: auto;
    svg {
      height: 45px;
      width: 45px;
    }
    span {
      display: none;
    }
  }
`;
export const StyleNavigationAddressWrapper = styled(SharedStyles)`
  img {
    height: 24px;
    width: 24px;
  }
  gap: 1em;
  color: #ffffff;
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
`;
export const StyleNavigationAddressText = styled.div`
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
  }
  address {
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 24px;
  }
`;

export const StyleNavigationSociaLogWrapper = styled.div`
  a {
    svg {
      height: 42px;
      width: 42px;
      margin: 0.5em;
    }
  }
  @media only screen and ${devices.mobile} {
    margin-top: -2.5em;
    a {
      svg {
        height: 42px;
        width: 42px;
        margin: 0.2em;
      }
    }
  }
  @media only screen and ${devices.tablet} {
    margin-top: -2.5em;
    a {
      svg {
        height: 42px;
        width: 42px;
        margin: 0.3em;
      }
    }
  }
  @media only screen and ${devices.tabletLarge} {
    margin-top: -2.5em;
    a {
      svg {
        height: 46px;
        width: 46px;
      }
    }
  }
`;

export const StyleNavigationContactWrapper = styled(SharedStyles)`
  gap: 1em;
  color: #ffffff;
  svg {
    height: 24px;
    width: 24px;
  }
  address {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
  }
  @media only screen and ${devices.mobile} {
    margin-top: -2.5em;
    margin-left: auto;
    gap: 0.5em;
    svg {
      height: 15px;
      width: 15px;
    }
    address {
      font-size: 1.1rem;
      line-height: 22px;
    }
    span {
      font-size: 0.8rem;
      line-height: 17px;
    }
  }
  @media only screen and ${devices.tablet} {
    margin-top: -2.5em;
    margin-left: auto;
    gap: 0.8em;
    svg {
      height: 15px;
      width: 15px;
    }
    address {
      font-size: 1.1rem;
      line-height: 22px;
    }
    span {
      font-size: 0.8rem;
      line-height: 17px;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    margin-top: -2.5em;
    margin-left: auto;
    gap: 0.8em;
    svg {
      height: 20px;
      width: 20px;
    }
    address {
      font-size: 1.2rem;
      line-height: 22px;
    }
    span {
      font-size: 0.9rem;
      line-height: 17px;
    }
  }
`;
export const StyleNavigationContactText = styled.div`
  text-align: end;
`;
export const StyleNavigationRequestCall = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.4em;
  cursor: pointer;
`;
export const StyleNavigationGreenEllipse = styled.div`
  width: 4px;
  height: 4px;
  background: #60f166;
  border-radius: 50%;
`;
