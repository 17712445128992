import React, { useState } from "react";
import RequestCallForm from "../molecules/RequestCallForm";
import {
  StyleFormSectionContainer,
  StyleMessageAlert,
} from "./styles/StyleFormSection";

type MessageAlertProps = {
  handleCloseMessageAlert: () => void;
  messageText: string;
};

function FormSection() {
  const [isMessageSend, setIsMessageSend] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>();

  const handleCloseMessageAlert = () => {
    setIsMessageSend(false);
  };
  const messageText =
    statusCode === 200
      ? "Мы получили ваш запрос на звонок, и мы перезвоним вам в ближайшее время"
      : "Не удалось получить ваш запрос на звонок. Повторите попытку.";
  return (
    <StyleFormSectionContainer>
      {isMessageSend ? (
        <MessageAlert
          handleCloseMessageAlert={handleCloseMessageAlert}
          messageText={messageText}
        />
      ) : (
        <RequestCallForm
          setIsMessageSend={setIsMessageSend}
          setStatusCode={setStatusCode}
        />
      )}
    </StyleFormSectionContainer>
  );
}

const MessageAlert = ({
  handleCloseMessageAlert,
  messageText,
}: MessageAlertProps) => {
  return (
    <StyleMessageAlert>
      <p>{messageText}</p>
      <button onClick={handleCloseMessageAlert}>Закрыть</button>
    </StyleMessageAlert>
  );
};

export default FormSection;
