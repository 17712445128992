import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

const StyleHeaderContainer = styled.header`
  margin-top: 1em;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index:100;
  height: 105px;
  @media only screen and ${devices.mobile} {
    margin-top: -1em;
    width: 95%;
  }
  @media only screen and ${devices.tablet} {
    margin-top: -1em;
    width: 95%;
  }
  @media only screen and ${devices.tabletLarge} {
    margin-top: -1em;
    width: 90%;
  }
`;

export default StyleHeaderContainer;
