import React from "react";
import Header from "../organisms/Header";
import Headlines from "../organisms/Headlines";
import SubHeadline from "../organisms/SubHeadline";
import { StyleHealineSectionContainer } from "./styles/StyleHeadlineSection";
import LeftSideShades from "../molecules/ShadowEffects/LeftSideShades";
import RightSideShades from "../molecules/ShadowEffects/RightSideShades";
import RightSideEllipse from "../molecules/ShadowEffects/RightSideEllipse";
import RightSmallSideEllipse from "../molecules/ShadowEffects/RightSmallSideEllipse";
import CreditScores from "../molecules/CreditScores";

function HeadlineSection() {
  return (
    <StyleHealineSectionContainer>
      <LeftSideShades />
      <RightSideShades />
      <RightSideEllipse/>
      <RightSmallSideEllipse/>
      <CreditScores/>
      <Header />
      <Headlines />
      <SubHeadline />
    </StyleHealineSectionContainer>
  );
}

export default HeadlineSection;
