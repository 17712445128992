import React from "react";
import { scroller } from "react-scroll";
import MenuListStyle from "./MenuListStyle";

type MenuListProps = {
  handleCloseMenu?: () => void;
};

function MenuList({ handleCloseMenu }: MenuListProps) {
  const handleScrollToSection = (sectionId: string) => {
    scroller.scrollTo(sectionId, {
      duration: 1500,
      deelay: 100,
      smooth: true,
      offset: -50,
    });
  };

  return (
    <MenuListStyle>
      <li>
        <span> Главная</span>
      </li>
      <li onClick={() => handleScrollToSection("consultant")}>
        <span>Консультация</span>
      </li>
      <li onClick={() => handleScrollToSection("stage_work")}>
        <span>Этапы работы</span>
      </li>
      <li onClick={() => handleScrollToSection("our_advantages")}>
        <span>Наши преимущества</span>
      </li>
      <li onClick={() => handleScrollToSection("successful_cases")}>
        <span>Успешные кейсы</span>
      </li>
      <li onClick={() => handleScrollToSection("form_section")}>
        <span>Контакты</span>
      </li>
    </MenuListStyle>
  );
}

export default MenuList;
