import React, { useState } from "react";
import type { DataOptions } from "../templates/mainSectionData";
import {
  SliderStyles,
  SliderItem,
  SliderItemLogoContainer,
  LeftArrow,
  RightArrow,
  SliderInnerContainer,
  SliderContainer,
} from "./styles/StyleMiniSlider";
import leftArrow from "./images/left-arrow.svg";
import rightArrow from "./images/right-arrow.svg";

type SliderProps = {
  slides: DataOptions;
};

function MiniSlider({ slides }: SliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState<null | number>(null);
  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === slides.length - 1;

  const goToPrevious = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diffrence = touchDown - currentTouch;
    if (diffrence > 5 && !isLastSlide) {
      goToNext();
    }
    if (diffrence < -5 && !isFirstSlide) {
      goToPrevious();
    }
    setTouchPosition(null);
  };

  return (
    <SliderStyles onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <LeftArrow
        onClick={goToPrevious}
        isFirstSlide={isFirstSlide}
        disabled={isFirstSlide}
      >
        <img src={leftArrow} alt="left arrow" />
      </LeftArrow>
      <RightArrow
        onClick={goToNext}
        isLastSlide={isLastSlide}
        disabled={isLastSlide}
      >
        <img src={rightArrow} alt="right arrow" />
      </RightArrow>
      <SliderContainer>
        <SliderInnerContainer
          slide_length={slides.length}
          current_index={currentIndex}
        >
          {slides.map((slide) => (
            <SliderItem key={slide.id}>
              <SliderItemLogoContainer>
                <img src={slide.img} alt="" />
              </SliderItemLogoContainer>
              <h5>{slide.title}</h5>
              <p>{slide.text}</p>
            </SliderItem>
          ))}
        </SliderInnerContainer>
      </SliderContainer>
    </SliderStyles>
  );
}

export default MiniSlider;
