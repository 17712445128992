import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const CreditWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 257px;
  height: 257px;
  left: 710px;
  top: 120px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 50%;
  @media only screen and ${devices.mobile} {
    width: 102px;
    height: 102px;
    left: 38px;
    top: 405px;
  }
  @media only screen and ${devices.tablet} {
    width: 102px;
    height: 102px;
    left: 60px;
    top: 420px;
  }
  @media only screen and (min-width: 600px) and (max-width: 619px) {
    left: 70px;
    top: 450px;
  }
  @media only screen and (min-width: 620px) and (max-width: 700px) {
    top: 440px;
  }
  @media only screen and (min-width: 701px) and (max-width: 768px) {
    left: 75px;
    top: 470px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 102px;
    height: 102px;
    left: 85px;
    top: 480px;
  }
  @media only screen and (min-width: 860px) and (max-width: 1024px) {
    left: 95px;
    top: 500px;
  }
  @media only screen and ${devices.laptop} {
    width: 180px;
    height: 180px;
    left: 360px;
    top: 190px;
  }
  @media only screen and (min-width: 1120px) and (max-width: 1200px) {
    left: 430px;
    top: 195px;
  }
  @media only screen and ${devices.large} {
    width: 180px;
    height: 180px;
    left: 560px;
    top: 220px;
  }
  @media only screen and (min-width: 1201px) and (max-width: 1329px) {
    left: 560px;
    top: 210px;
  }
  @media only screen and (min-width: 1330px) and (max-width: 1500px) {
    left: 690px;
    top: 210px;
  }
  @media only screen and (min-width: 1501px) and (max-width: 1649px) {
    left: 710px;
    top: 160px;
  }
  @media only screen and (min-width: 1650px) {
    left: 790px;
    top: 150px;
  }
`;

const SharedStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;
export const InnerCreditScoreContainer = styled(SharedStyles)`
  width: 214px;
  height: 214px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  @media only screen and ${devices.mobile} {
    width: 84px;
    height: 84px;
  }
  @media only screen and ${devices.tablet} {
    width: 84px;
    height: 84px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 84px;
    height: 84px;
  }
  @media only screen and ${devices.laptop} {
    width: 150px;
    height: 150px;
  }
  @media only screen and ${devices.large} {
    width: 150px;
    height: 150px;
  }
`;

export const DeepInnerCreditScoreContainer = styled(SharedStyles)`
  z-index: 1;
  width: 179px;
  height: 179px;
  @media only screen and ${devices.mobile} {
    width: 64px;
    height: 64px;
  }
  @media only screen and ${devices.tablet} {
    width: 64px;
    height: 64px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 64px;
    height: 64px;
  }
  @media only screen and ${devices.laptop} {
    width: 110px;
    height: 110px;
  }
  @media only screen and ${devices.large} {
    width: 110px;
    height: 110px;
  }
  background: linear-gradient(
    146.78deg,
    #ffffff 20.04%,
    rgba(255, 255, 255, 0.91) 88.35%
  );
  flex-direction: column;
  justify-content: none !important;
  text-align: center;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 51px;
    background: linear-gradient(104.39deg, #e73636 7.67%, #ca2727 95.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0px;
    @media only screen and ${devices.mobile} {
      font-size: 20px;
    }
    @media only screen and ${devices.tablet} {
      font-size: 20px;
    }
    @media only screen and ${devices.tabletLarge} {
      font-size: 20px;
    }
    @media only screen and ${devices.large} {
      font-size: 23px;
    }
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 24px;
    color: #000000;
    @media only screen and ${devices.mobile} {
      display: none;
    }
    @media only screen and ${devices.tablet} {
      display: none;
    }
    @media only screen and ${devices.tabletLarge} {
      display: none;
    }
    @media only screen and ${devices.laptop} {
      display: none;
    }
    @media only screen and ${devices.large} {
      font-size: 0.6rem;
    }
  }
  img {
    width: 84px;
    height: 84px;
    position: absolute;
    top: -23.2px;
    @media only screen and ${devices.mobile} {
      top: -14px;
      width: 40px;
      height: 40px;
    }
    @media only screen and ${devices.tablet} {
      top: -14px;
      width: 40px;
      height: 40px;
    }
    @media only screen and ${devices.tabletLarge} {
      top: -14px;
      width: 40px;
      height: 40px;
    }
  }
`;
