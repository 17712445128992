import React from "react";
function ShieldIcon() {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8151 6.17977L19.5651 3.08977C18.7101 2.77477 17.3151 2.77477 16.4601 3.08977L8.21008 6.17977C6.62008 6.77977 5.33008 8.63977 5.33008 10.3348V22.4847C5.33008 23.6997 6.12508 25.3047 7.10008 26.0247L15.3501 32.1897C16.8051 33.2847 19.1901 33.2847 20.6451 32.1897L28.8951 26.0247C29.8701 25.2897 30.6651 23.6997 30.6651 22.4847V10.3348C30.6801 8.63977 29.3901 6.77977 27.8151 6.17977ZM23.2251 14.5798L16.7751 21.0297C16.5501 21.2547 16.2651 21.3597 15.9801 21.3597C15.6951 21.3597 15.4101 21.2547 15.1851 21.0297L12.7851 18.5997C12.3501 18.1647 12.3501 17.4447 12.7851 17.0097C13.2201 16.5747 13.9401 16.5747 14.3751 17.0097L15.9951 18.6297L21.6501 12.9748C22.0851 12.5398 22.8051 12.5398 23.2401 12.9748C23.6751 13.4098 23.6751 14.1448 23.2251 14.5798Z"
        fill="white"
      />
    </svg>
  );
}

export default ShieldIcon;
