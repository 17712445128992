import telephoneLogo from "./logos/phone-second.svg";
import starLogo from "./logos/star.svg";
import timeLogo from "./logos/time.svg";
import miniCarLogo from "./logos/car-seecond.svg";
import unionLogo from "./logos/union.svg";
import redMiniCar from "./logos/car-red.svg";
import redStar from "./logos/star-red.svg";
import redSubtract from "./logos/subtract-red.svg";
import redUnion from "./logos/union-1-red.svg";
import seconRedUnion from "./logos/union-2-red.svg";
import redWalletLogo from "./logos/wallet-red.svg";

export type DataOptions = {
    id: number;
    img: string;
    title: string;
    text: string;
}[];

export const cooperationListData: DataOptions = [
    {
        id: 1,
        img: `${telephoneLogo}`,
        title: "Входящая заявка",
        text: "Оставьте заявку через сайт, либо свяжитесь с нами по телефону через мессенджер "
    },
    {
        id: 2,
        img: `${starLogo}`,
        title: "Онлайн оценка",
        text: "Мы проведем индивидуальную консультацию онлайн и оценим стоимость автомобиля"
    },
    {
        id: 3,
        img: `${timeLogo}`,
        title: "Выезд на осмотр",
        text: "Мы встретимся с Вами в назначенное время для проведения осмотра автомобиля"
    },
    {
        id: 4,
        img: `${miniCarLogo}`,
        title: "Осмотр автомобиля",
        text: "Мы приедем на осмотр в любое удобное для вас место и время"
    },
    {
        id: 5,
        img: `${unionLogo}`,
        title: "Документация",
        text: "Мы подготовим всю необходимую документацию для проведения покупки автомобиля"
    }
]

export const reasonToChooseOurCompanyListData: DataOptions = [
    {
        id: 1,
        img: `${redMiniCar}`,
        title: "700+ автомобилей",
        text: "Выкуплено более 700 автомобилей по всей РФ"
    },
    {
        id: 2,
        img: `${redStar}`,
        title: "Оценка авто",
        text: "Бесплатная и быстрая оценка вашего автомобиля"
    },
    {
        id: 3,
        img: `${redSubtract}`,
        title: "Trade-in",
        text: "Предоставим скидку на наши авто по схеме TRADE-IN"
    },
    {
        id: 4,
        img: `${redUnion}`,
        title: "Документация",
        text: "Юридически грамотное составление и заполнение документов на авто"
    },
    {
        id: 5,
        img: `${seconRedUnion}`,
        title: "Проведение оплаты",
        text: "Получение денег любым удобным способом (наличными / на карту / безналичными)"
    },
    {
        id: 6,
        img: `${redWalletLogo}`,
        title: "Оформление кредита",
        text: "Мы поможем клиенту не только с покупкой авто, но и с оформлением кредита"
    }
]