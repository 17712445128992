import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const StyleFooterContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  flex-direction: column;
  & .footer_text_wrapper {
    width: 1081px;
    @media only screen and ${devices.mobile} {
      display: none;
    }
    @media only screen and ${devices.tablet} {
      display: none;
    }
    @media only screen and ${devices.tabletLarge} {
      display: none;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 24px;
      color: #787878;
    }
  }
`;

export const StyleFooterPartition = styled.div`
  width: 1081px;
  height: 0px;
  border: 1px solid #d5d5d5;
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
  @media only screen and ${devices.laptop} {
    width: 940px;
  }
`;
