import React from "react";
import HeadlineSection from "../templates/HeadlineSection";
import MainSection from "../templates/MainSection";
import FormSection from "../templates/FormSection";
import CarouselSection from "../templates/CarouselSection";
import BottomPageSection from "../templates/BottomPageSection";
import {
  GlobalStyles,
  StyleHomeContainer,
} from "./StyleHome";

function Home() {
  return (
    <>
      <GlobalStyles />
      <StyleHomeContainer>
        <HeadlineSection />
        <MainSection />
        <FormSection />
        <CarouselSection />
        <BottomPageSection />
      </StyleHomeContainer>
    </>
  );
}

export default Home;
