import React from "react";
import StyleFooterMenu from "./styles/StyleFooterMenu";
import MenuList from "../atoms/menuList/MenuList";

function FooterMenu() {
  return (
    <StyleFooterMenu>
      <MenuList />
    </StyleFooterMenu>
  );
}

export default FooterMenu;
