import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const StyleFormSectionContainer = styled.div`
  width: 1170px;
  height: 443px;
  background: #cb2828;
  border-radius: 25px;
  margin: 1em auto;
  display: flex;
  @media only screen and ${devices.mobile} {
    width: 90%;
    height: 600px;
  }
  @media only screen and ${devices.tablet} {
    width: 90%;
    height: 600px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 90%;
    height: 600px;
  }
  @media only screen and ${devices.laptop} {
    width: 95%;
  }
`;
export const StyleMessageAlert = styled.div`
  margin: auto;
  width: 400px;
  background: white;
  border-radius: 20px;
  padding: 1em;
  text-align: center;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 25px;
  }
  button {
    background: none;
    border: none;
    font-size: 0.9rem;
    cursor: pointer;
  }
`;
