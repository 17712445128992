import React from "react";
import { scroller } from "react-scroll";
import MenuIcon from "../atoms/icons/MenuIcon";
import LocationIcon from "../atoms/icons/LocationIcon";
import WhatappsIcon from "../atoms/icons/WhatappsIcon";
import TelegramIcon from "../atoms/icons/TelegramIcon";
import TelephoneIcon from "../atoms/icons/TelephoneIcon";
import {
    StyleNavigationContainer,
    StyleNavigationLogo,
    StyleNavigationTextWrapper,
    StyleNavigationMenuWrapper,
    StyleNavigationAddressWrapper,
    StyleNavigationAddressText,
    StyleNavigationSociaLogWrapper,
    StyleNavigationContactWrapper,
    StyleNavigationContactText,
    StyleNavigationRequestCall,
    StyleNavigationGreenEllipse,
} from "./styles/StyleNavigation";
import CompanyLogoWhite from "../atoms/icons/CompanyLogoWhite";
import ViberIcon from "../atoms/icons/ViberIcon";

type NavigationProps = {
    handleOpenNavMenu: () => void;
    menuRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Navigation({ handleOpenNavMenu, menuRef}: NavigationProps) {
    const handleScrollToForm = () => {
        scroller.scrollTo("form_section", {
            duration: 1500,
            deelay: 100,
            smooth: true,
            offset: -50,
        });
    };
    return (
        <StyleNavigationContainer>
            <StyleNavigationLogo>
                <CompanyLogoWhite />
            </StyleNavigationLogo>

            <StyleNavigationTextWrapper>
                <span>Выкуп авто по рыночной цене</span>
            </StyleNavigationTextWrapper>

            <StyleNavigationMenuWrapper onClick={handleOpenNavMenu} ref={menuRef}>
                <MenuIcon />
                <span>Меню </span>
            </StyleNavigationMenuWrapper>

            <div className="break_to_new_row"></div>

            <StyleNavigationAddressWrapper>
                <LocationIcon />
                <StyleNavigationAddressText>
                    <span>Наш адрес</span>
                    <address>Жулебинский бульвар 5</address>
                </StyleNavigationAddressText>
            </StyleNavigationAddressWrapper>

            <StyleNavigationSociaLogWrapper>
                <WhatappsIcon />
                <TelegramIcon />
                <ViberIcon />
            </StyleNavigationSociaLogWrapper>

            <StyleNavigationContactWrapper>
                <TelephoneIcon />
                <StyleNavigationContactText>
                    <address>8-926-000-26-01</address>
                    <StyleNavigationRequestCall onClick={handleScrollToForm}>
                        <StyleNavigationGreenEllipse />
                        <span>Заказать звонок</span>
                    </StyleNavigationRequestCall>
                </StyleNavigationContactText>
            </StyleNavigationContactWrapper>
        </StyleNavigationContainer>
    );
}

export default Navigation;
