import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const StyleLeftSideShades = styled.div`
  position: absolute;
  width: 256.06px;
  height: 1121.39px;
  background: #f97272;
  opacity: 0.04;
  left: 30px;
  top: -330px;
  transform: rotate(45deg);
  @media only screen and ${devices.mobile} {
    width: 108px;
    height: 473px;
    left: 40px;
    top: -120px;
  }
  @media only screen and ${devices.tablet} {
    width: 108px;
    height: 473px;
    left: 40px;
    top: -120px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 128px;
    height: 573px;
    left: 70px;
    top: -140px;
  }
  @media only screen and ${devices.laptop} {
    width: 160px;
    height: 573px;
    left: 10px;
    top: -170px;
  }
  @media only screen and ${devices.large} {
    width: 160px;
    height: 573px;
    left: 10px;
    top: -170px;
  }
`;

export const StyleRightSideShades = styled.div`
  position: absolute;
  width: 498.16px;
  height: 1130px;
  left: 990px;
  top: -150px;
  background: #9f2d2d;
  opacity: 0.15;
  transform: rotate(45deg);
  @media only screen and ${devices.mobile} {
    width: 157px;
    height: 700px;
    left: 270px;
    top: 180px;
  }
  @media only screen and ${devices.tablet} {
    width: 157px;
    height: 710px;
    left: 280px;
    top: 180px;
  }
  @media only screen and (min-width: 520px) and (max-width: 619px) {
    width: 180px;
    left: 340px;
    top: 200px;
  }
  @media only screen and (min-width: 620px) and (max-width: 768px) {
    width: 220px;
    left: 490px;
    top: 200px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 250px;
    height: 880px;
    left: 550px;
    top: 220px;
  }
  @media only screen and (min-width: 890px) and (max-width: 1024px){
    left: 720px;
    top: 220px;
  }
  @media only screen and ${devices.laptop} {
    width: 400px;
    height: 1200px;
    left: 600px;
    top: -150px;
  }
  @media only screen and ${devices.large} {
    width: 380px;
    height: 1200px;
    left: 860px;
    top: -160px;
  }
`;

export const StyleRightSideEllipse = styled.div`
  position: absolute;
  width: 825px;
  height: 825px;
  left: 800px;
  top: 34px;
  background: #e63636;
  opacity: 0.15;
  filter: blur(127.5px);
  @media only screen and ${devices.mobile} {
    opacity: 0.35;
    width: 153.04px;
    height: 153.04px;
    left: 300px;
    top: 420px;
  }
  @media only screen and ${devices.tablet} {
    opacity: 0.35;
    width: 153.04px;
    height: 153.04px;
    left: 490px;
    top: 430px;
  }
  @media only screen and ${devices.tabletLarge} {
    opacity: 0.35;
    width: 153.04px;
    height: 153.04px;
    left: 790px;
    top: 500px;
  }
`;

export const StyleRightSmallEllipse = styled.div`
  position: absolute;
  width: 111px;
  height: 111px;
  left: 1350px;
  top: 270px;
  background: #ff1f1f;
  mix-blend-mode: screen;
  filter: blur(77.5px);
`;

export const StyleMiddleSmallEllipse = styled.div`
  position: absolute;
  width: 111px;
  height: 111px;
  left: 800px;
  top: 330px;
  background: #ffffff;
  mix-blend-mode: screen;
  opacity: 0.55;
  filter: blur(77.5px);
`;

export const StyleCarouselLeftSideShades = styled.div`
  position: absolute;
  width: 256.06px;
  height: 1121.39px;
  background: #f97272;
  opacity: 0.04;
  left: 30px;
  top: -300px;
  transform: rotate(45deg);
`;

export const StyleCarouselRightSideShades = styled.div`
  position: absolute;
  width: 298.16px;
  height: 1413.86px;
  right: 90px;
  top: 110px;
  background: #9f2d2d;
  opacity: 0.15;
  transform: rotate(45deg);
`;
