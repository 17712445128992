import bmwFront from "./carImages/bmw_front.webp";
import bmwBack from "./carImages/bmw_back.webp";
import bmwLeft from "./carImages/bmw_left.webp";
import bmwRight from "./carImages/bmw_right.webp";
import bmwInside from "./carImages/bmw_inside.webp";
import benzFront from "./carImages/benz_front.webp";
import benzBack from "./carImages/benz_back.webp";
import benzLeft from "./carImages/benz_left.webp";
import benzRight from "./carImages/benz_front.webp";
import benzInside from "./carImages/benz_inside.webp";
import porscheSfront from "./carImages/porsche_s_front.webp";
import porscheSback from "./carImages/porsche_s_back.webp";
import porscheSinside from "./carImages/porsche_s_inside.webp";
import porscheSleft from "./carImages/porsche_s_left.webp";
import porscheSright from "./carImages/porsche_s_right.webp";
import volkswagenFront from "./carImages/volkswagen_front.webp";
import volkswagenBack from "./carImages/volkswagen_back.webp";
import volkswagenInside from "./carImages/voklswagen_inside.webp";
import volkswagenLeft from "./carImages/volkswagen_left.webp";
import volkswagenRight from "./carImages/volkswagen_right.webp";
import porscheTurboFront from "./carImages/porsche_turbo_front.webp"
import porscheTurboBack from "./carImages/porsche_turbo_back.webp"
import porscheTurboInside from "./carImages/porsche_turbo_inside.webp"
import porscheTurboLeft from "./carImages/porsche_turbo_left.webp"
import porscheTurboRight from "./carImages/porsche_turbo_right.webp"
import porscheMacanFront from "./carImages/porsh_macan_front.webp";
import porscheMacanBack from "./carImages/porsh_macan_back.webp";
import porscheMacanInside from "./carImages/porsh_macan_inside.webp";
import porscheMacanLeft from "./carImages/porsh_macan_left.webp";
import porscheMacanRight from "./carImages/porsh_macan_right.webp";

export type SuccessDataOptions = {
    id: number;
    images: {
        img1: string;
        img2: string;
        img3: string;
        img4: string;
        img5: string;
    },
    car_name: string;
    date_of_redemption: string;
    car_history: {
        year_of_manufacture: string;
        volume: string;
        power: string;
        gear_box: string;
        drive_unit: string;
        owners: string;
        mileage: string;
        redemption: string;
    }
}[];


const succeessfulHistoryListData: SuccessDataOptions = [
    {
        id: 1,
        images: {
            img1: benzFront,
            img2: benzBack,
            img3: benzInside,
            img4: benzLeft,
            img5: benzRight,
        }
        ,
        car_name: "Mercedes-Benz GL",
        date_of_redemption: "Апрель 2023",
        car_history: {
            year_of_manufacture: "2006",
            volume: "4.7 литра",
            power: "340 л.с.",
            gear_box: "Автоматическая",
            drive_unit: "полный",
            owners: "5",
            mileage: "208,000 км",
            redemption: "700,000 рублей"
        }
    },
    {
        id: 2,
        images: {
            img1: bmwFront,
            img2: bmwBack,
            img3: bmwInside,
            img4: bmwLeft,
            img5: bmwRight
        }
        ,
        car_name: "BMW 325",
        date_of_redemption: "Апрель 2023",
        car_history: {
            year_of_manufacture: "2011",
            volume: "2.5 литра",
            power: "218 л.с",
            gear_box: "Автоматическая",
            drive_unit: "полный",
            owners: "4",
            mileage: "144.000 км",
            redemption: "900,000 рублей"
        }
    },
    {
        id: 3,
        images: {
            img1: porscheMacanFront,
            img2: porscheMacanBack,
            img3: porscheMacanInside,
            img4: porscheMacanLeft,
            img5: porscheMacanRight
        }
        ,
        car_name: "Porsche Macan S",
        date_of_redemption: "Февраль 2023",
        car_history: {
            year_of_manufacture: "2019",
            volume: "3.0 литра",
            power: "340 л.с",
            gear_box: "Автоматическая",
            drive_unit: "полный",
            owners: "1",
            mileage: "59,000км",
            redemption: "5,000,000 рублей"
        }
    },
    {
        id: 4,
        images: {
            img1: volkswagenFront,
            img2: volkswagenBack,
            img3: volkswagenInside,
            img4: volkswagenLeft,
            img5: volkswagenRight
        }
        ,
        car_name: "Volkswagen Tiguan",
        date_of_redemption: "Февраль 2023",
        car_history: {
            year_of_manufacture: "2011",
            volume: "2.0 литра",
            power: "170 л.с",
            gear_box: "Автоматическая",
            drive_unit: "полный",
            owners: "3",
            mileage: "149,000 км",
            redemption: "730,000 рублей"
        }
    },
    {
        id: 5,
        images: {
            img1: porscheTurboFront,
            img2: porscheTurboBack,
            img3: porscheTurboInside,
            img4: porscheTurboLeft,
            img5: porscheTurboRight
        }
        ,
        car_name: "Porsche Macan Turbo ",
        date_of_redemption: "Март 2023",
        car_history: {
            year_of_manufacture: "2019",
            volume: "3.0 литра",
            power: "440 л.с",
            gear_box: "Автоматическая",
            drive_unit: "полный",
            owners: "1",
            mileage: "27,000 км",
            redemption: "5,900,000 рублей"
        }
    },
];

export { succeessfulHistoryListData }
