import React, {ImgHTMLAttributes} from "react";
import redVerified from "./verified-check.png";

interface VerifiedIconProps extends ImgHTMLAttributes<HTMLImageElement>{}

function VerifiedIcon({...rest}:VerifiedIconProps) {
  return <img src={redVerified} alt="" {...rest}/>;
}

export default VerifiedIcon;
