import React from "react";
import { scroller } from "react-scroll";
import ShieldIcon from "../atoms/icons/ShieldIcon";
import car from "./images/car.png";
import arrowDown from "./images/arrow-down.svg";
import {
  HeadlineWrapper,
  HeadlineLeftSide,
  HeadlineImageWrapper,
  HeadlineRightSide,
  HeadlineButton,
} from "./styles/StyleHeadline";
function Headlines() {
  const handleScrollToForm = () => {
    scroller.scrollTo("form_section", {
      duration: 1500,
      deelay: 100,
      smooth: true,
      offset: -50,
    });
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: 550,
      behavior: "smooth",
    });
  };
  return (
    <HeadlineWrapper>
      <HeadlineLeftSide>
        <div className="headline_topic">
          <h2>
            Выкуп автомобилей <br />
            <span>по наиболее выгодной рыночной цене</span>
          </h2>
        </div>
        <div className="headline_support_copy">
          <ShieldIcon />
          <h4>
            Мы готовы провести индивидуальную консультацию 24/7 и бесплатно дать
            оценку вашему автомобилю
          </h4>
        </div>
        <HeadlineButton onClick={handleScrollToForm}>
          Получить оценку вашего авто
        </HeadlineButton>
      </HeadlineLeftSide>

      <HeadlineImageWrapper>
        <img src={car} alt="car" />
      </HeadlineImageWrapper>

      <HeadlineRightSide>
        <p>Листать ниже</p>
        <div className="arrow_down_wrapper" onClick={handleScrollDown}>
          <img src={arrowDown} alt="arrow down" />
        </div>
      </HeadlineRightSide>
    </HeadlineWrapper>
  );
}

export default Headlines;
