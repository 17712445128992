import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

const StyleFooterMenu = styled.div`
  ul {
    display: flex;
    list-style: none;
    gap: 2em;
    @media only screen and ${devices.mobile} {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media only screen and ${devices.tablet} {
      flex-wrap: wrap;
      justify-content: center;
    }
    @media only screen and ${devices.tabletLarge} {
      flex-wrap: wrap;
      justify-content: center;
    }
    li {
      font-style: normal;
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 24px;
      a {
        color: #787878;
      }
    }
    @media only screen and ${devices.mobile} {
      li:nth-child(6) {
        display: none;
      }
    }
    @media only screen and ${devices.tablet} {
      li:nth-child(6) {
        display: none;
      }
    }
  }
`;

export default StyleFooterMenu;
