import styled from "styled-components";
interface Props {
  slide_length: number;
  current_index: number;
}
interface ArrowProps {
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
}

export const SliderStyles = styled.div`
  height: 100%;
  position: relative;
`;

export const SliderContainer = styled.div`
  height: 100%;
  overflow-x: clip;
`;

export const SliderInnerContainer = styled.div<Props>`
  display: flex;
  height: 100%;
  transition: transform ease-out 0.3s;
  width: ${(props) => 450 * props.slide_length}px;
  transform: translateX(${(props) => -(props.current_index * 450)}px);
  @media only screen and (max-width: 480px) {
    width: ${(props) => 340 * props.slide_length}px;
    transform: translateX(${(props) => -(props.current_index * 340)}px);
  }
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    width: ${(props) => 550 * props.slide_length}px;
    transform: translateX(${(props) => -(props.current_index * 550)}px);
  }
`;

export const SliderItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fdfdfe;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 35px rgba(59, 88, 124, 0.15);
  position: relative;
  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #141414;
    margin: 0px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 24px;
    text-align: center;
    color: #4b3737;
    width: 220px;
    margin: 0px;
  }
`;

export const SliderItemLogoContainer = styled.div`
  width: 59px;
  height: 59px;
  background: #ca2828;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1.7em;
`;

export const SharedArrowStyles = styled.button<ArrowProps>`
  width: 32px;
  height: 32px;
  border:none;
  background: #df6565;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 1;
`;

export const LeftArrow = styled(SharedArrowStyles)`
  background: ${(props) => props.isFirstSlide && "#824D4D"};
  left: -14px;
`;

export const RightArrow = styled(SharedArrowStyles)`
  background: ${(props) => props.isLastSlide && "#824D4D"};
  right: -14px;
`;
