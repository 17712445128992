import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const StyleFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const StyleFormHead = styled.div`
  text-align: center;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 55px;
    color: #ffffff;
    margin: 0px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 30px;
    color: #f3a5a5;
  }
  @media only screen and ${devices.mobile} {
    h4 {
      font-size: 1.4rem;
      line-height: 32px;
    }
    p {
      font-size: 1rem;
      line-height: 26px;
      width: 304px;
    }
  }
  @media only screen and ${devices.tablet} {
    h4 {
      font-size: 1.4rem;
      line-height: 32px;
    }
    p {
      font-size: 1rem;
      line-height: 26px;
      width: 304px;
    }
  }

  @media only screen and ${devices.tabletLarge} {
    h4 {
      font-size: 1.4rem;
      line-height: 32px;
    }
    p {
      font-size: 1rem;
      line-height: 26px;
      width: 304px;
    }
  }
`;

export const StyleFormBody = styled.div`
  form {
    display: flex;
    gap: 1.3em;
    input {
      width: 260px;
      height: 95.22px;
      background: #ffffff;
      border-radius: 20px;
      outline: none;
      border: none;
      padding: 1em;
      @media only screen and ${devices.mobile} {
        width: 302px;
        height: 64px;
      }
      @media only screen and ${devices.tablet} {
        width: 302px;
        height: 64px;
      }
      @media only screen and ${devices.tabletLarge} {
        width: 302px;
        height: 64px;
      }
      @media only screen and ${devices.laptop} {
        width: 225px;
        height: 80px;
      }
      & ::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 30px;
        color: #8a8a8a;
        @media only screen and ${devices.tablet} {
          font-size: 0.8rem;
        }
        @media only screen and ${devices.tabletLarge} {
          font-size: 0.8rem;
        }
      }
    }
    @media only screen and ${devices.mobile} {
      flex-direction: column;
    }
    @media only screen and ${devices.tablet} {
      flex-direction: column;
    }
    @media only screen and ${devices.tabletLarge} {
      flex-direction: column;
    }
  }
`;

export const SubmitButton = styled.button`
  width: 260px;
  height: 91px;
  background: #ffffff;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 30px;
  color: #141414;
  @media only screen and ${devices.mobile} {
    font-size: 0.9rem;
    line-height: 24px;
    width: 302px;
    height: 50px;
    font-weight: 400;
  }
  @media only screen and ${devices.tablet} {
    font-size: 0.9rem;
    line-height: 24px;
    width: 302px;
    height: 64px;
    font-weight: 400;
  }
  @media only screen and ${devices.tabletLarge} {
    font-size: 0.9rem;
    line-height: 24px;
    width: 302px;
    height: 64px;
    font-weight: 400;
  }
  @media only screen and ${devices.laptop} {
    width: 225px;
    height: 80px;
  }
`;

export const StyleFormFooter = styled.div`
  display: flex;
  gap: 2em;
  @media only screen and ${devices.mobile} {
    flex-direction: column-reverse;
    gap: 0.5em;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column-reverse;
    gap: 1.5em;
  }

  & .form_footer_left_side {
    display: flex;
    align-items: center;
    gap: 1.5em;
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 30px;
      color: #ffffff;
    }
    a {
      svg {
        height: 40px;
        width: 40px;
      }
    }
     {
    }
    @media only screen and ${devices.mobile} {
      gap: 0.8em;
      justify-content: center;
      span {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 18px;
        width: 130px;
      }
      a {
        margin: 0px;
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
    @media only screen and ${devices.tablet} {
      gap: 0.8em;
      justify-content: center;
      span {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 24px;
        width: 144px;
      }
      a {
        margin: 0px;
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
    @media only screen and ${devices.tabletLarge} {
      gap: 0.8em;
      justify-content: center;
      span {
        font-weight: 500;
        font-size: 0.7rem;
        line-height: 24px;
        width: 144px;
      }
      a {
        margin: 0px;
        svg {
          height: 40px;
          width: 40px;
        }
      }
    }
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 26px;
    color: #f3a5a5;
    width: 426px;
    @media only screen and ${devices.mobile} {
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 22px;
      width: 323px;
      text-align: center;
    }
    @media only screen and ${devices.tablet} {
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 22px;
      width: 323px;
      text-align: center;
    }
    @media only screen and ${devices.tabletLarge} {
      font-weight: 400;
      font-size: 0.6rem;
      line-height: 22px;
      width: 323px;
      text-align: center;
    }
  }
`;
