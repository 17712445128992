import React from "react";
import FooterNavigation from "../molecules/FooterNavigation";
import FooterMenu from "../molecules/FooterMenu";
import {
    StyleFooterContainer,
    StyleFooterPartition,
} from "./styles/StyleFooter";

function Footer() {
    return (
        <StyleFooterContainer>
            <FooterNavigation />
            <StyleFooterPartition />
            <FooterMenu />
            <div className="footer_text_wrapper">
                <p>
                    Содержимое сайта является объектом авторских прав. ©2023 ООО
                    "FastCarSale.ru". Все права защищены. Запрещается копировать,
                    использовать, распространять, модифицировать любое содержимое этого
                    сайта. Разработано Puggle
                </p>
            </div>
        </StyleFooterContainer>
    );
}

export default Footer;
