import React from "react";
import CompanyLogoBlack from "../atoms/icons/CompanyLogoBlack";
import LocationIcon from "../atoms/icons/LocationIcon";
import WhatappsIcon from "../atoms/icons/WhatappsIcon";
import TelegramIcon from "../atoms/icons/TelegramIcon";
import TelephoneIcon from "../atoms/icons/TelephoneIcon";
import {
  StyleFooterNavContainer,
  StyleFooterNavAddress,
  StyleFooterNavSocialLogo,
  StyleFooterNavContact,
  InnerConatinerLeft,
  InnerConatinerRight,
  FooterNavigationAddressText,
  FooterNavigationContactText,
  FooterNavigationRequestCall,
  FooterNavigationGreenEllipse,
} from "./styles/StyleFooterNavigation";
import { scroller } from "react-scroll";
import ViberIcon from "../atoms/icons/ViberIcon";

function FooterNavigation() {
  const handleScrollToForm = () => {
    scroller.scrollTo("form_section", {
      duration: 1500,
      deelay: 100,
      smooth: true,
      offset: -50,
    });
  };
  return (
      <StyleFooterNavContainer>
        <InnerConatinerLeft>
          <CompanyLogoBlack />
          <span>Выкуп авто по рыночной цене</span>
        </InnerConatinerLeft>

        <InnerConatinerRight>
          <StyleFooterNavAddress>
            <LocationIcon />
            <FooterNavigationAddressText>
              <span>Наш адрес</span>
              <address>Жулебинский бульвар 5</address>
            </FooterNavigationAddressText>
          </StyleFooterNavAddress>

          <StyleFooterNavSocialLogo>
            <WhatappsIcon />
            <TelegramIcon />
            <ViberIcon />
          </StyleFooterNavSocialLogo>

          <StyleFooterNavContact>
            <TelephoneIcon />
            <FooterNavigationContactText>
              <address>8-926-000-26-01</address>
              <FooterNavigationRequestCall onClick={handleScrollToForm}>
                <FooterNavigationGreenEllipse />
                <span>Заказать звонок</span>
              </FooterNavigationRequestCall>
            </FooterNavigationContactText>
          </StyleFooterNavContact>
        </InnerConatinerRight>
      </StyleFooterNavContainer>
  );
}
export default FooterNavigation;
