import styled, { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
  html{
    box-sizing: border-box;
    font-size: 16px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body{
    font-family: 'Montserrat', sans-serif, --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue';
    padding: 0;
    margin: 0;
  }
`;

export const StyleHomeContainer = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
  min-width: 0;
  overflow:hidden;
`;
