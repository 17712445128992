import React from "react";
import VerifiedIcon from "../atoms/icons/VerifiedIcon";
import {
  CreditWrapper,
  InnerCreditScoreContainer,
  DeepInnerCreditScoreContainer,
} from "./styles/StyleCreditScores";

function CreditScores() {
  return (
    <CreditWrapper>
      <InnerCreditScoreContainer>
        <DeepInnerCreditScoreContainer>
          <VerifiedIcon />
          <h3>700+</h3>
          <span>
            Авто выкуплено
          </span>
        </DeepInnerCreditScoreContainer>
      </InnerCreditScoreContainer>
    </CreditWrapper>
  );
}

export default CreditScores;
