import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import TelegramIcon from "../atoms/icons/TelegramIcon";
import WhatappsIcon from "../atoms/icons/WhatappsIcon";
import {
  StyleFormContainer,
  StyleFormHead,
  StyleFormBody,
  StyleFormFooter,
  SubmitButton,
} from "./styles/StyleRequestCallForm";
import useForm from "../../hooks/useForm";
import ViberIcon from "../atoms/icons/ViberIcon";

type RequestCallFormProps = {
  setStatusCode: React.Dispatch<React.SetStateAction<number | undefined>>;
  setIsMessageSend: React.Dispatch<React.SetStateAction<boolean>>;
};

interface RequestCallFormField {
  guest_name: string;
  phone_number: string;
  car_plate_number: string;
}
function RequestCallForm({
  setIsMessageSend,
  setStatusCode,
}: RequestCallFormProps) {
  const { handleChange, resetForm, formState } = useForm<RequestCallFormField>({
    initialState: {
      guest_name: "",
      phone_number: "",
      car_plate_number: "",
    },
  });
  const form = useRef<HTMLFormElement>(null);
  const handleSendMessage = async (e: React.FormEvent) => {
    const { REACT_APP_PUBLIC_ID, REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID } =
      process.env;
    e.preventDefault();
    resetForm();
    if (form.current != undefined) {
      emailjs
        .sendForm(
          REACT_APP_SERVICE_ID,
          REACT_APP_TEMPLATE_ID,
          form.current,
          REACT_APP_PUBLIC_ID
        )
        .then(
          (result) => {
            setStatusCode(result.status);
            setIsMessageSend(true);
          },
          (error) => {
            setStatusCode(error.status);
          }
        );
    }
  };

  return (
    <StyleFormContainer id="form_section">
      <StyleFormHead>
        <h4>Всё ещё остались вопросы?</h4>
        <p>Оставьте свои данные, и мы свяжемся с вами в течение 15 минут</p>
      </StyleFormHead>

      <StyleFormBody>
        <form onSubmit={handleSendMessage} ref={form}>
          <input
            type="text"
            placeholder="Ваше имя"
            name="guest_name"
            onChange={handleChange}
            value={formState.guest_name}
          />
          <input
            type="text"
            placeholder="Ваш телефон"
            name="phone_number"
            onChange={handleChange}
            value={formState.phone_number}
          />
          <input
            type="text"
            placeholder="Госномер авто"
            name="car_plate_number"
            onChange={handleChange}
            value={formState.car_plate_number}
          />
          <SubmitButton>Оставить заявку</SubmitButton>
        </form>
      </StyleFormBody>

      <StyleFormFooter>
        <div className="form_footer_left_side">
          <span>Свяжитесь с нами через мессенджеры</span>
          <WhatappsIcon />
          <TelegramIcon />
          <ViberIcon />
        </div>
        <p>
          Нажимая на кнопку, вы соглашаетесь с политикой конфиденциальности
          нашей компании
        </p>
      </StyleFormFooter>
    </StyleFormContainer>
  );
}

export default RequestCallForm;
