import React, { HTMLAttributes } from "react";
import { StyleLeftArrow, StyleRightArrow } from "./StyleArrowButtons";

interface ArrowButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
}

const LeftArrow = ({ isFirstSlide, ...rest }: ArrowButtonProps) => {
  return (
    <StyleLeftArrow
      {...rest}
      disabled={isFirstSlide}
      isFirstSlide={isFirstSlide}
    >
      <svg
        width="13"
        height="23"
        viewBox="0 0 13 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.625 21.75L1.375 11.5L11.625 1.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyleLeftArrow>
  );
};

const RightArrow = ({ isLastSlide, ...rest }: ArrowButtonProps) => {
  return (
    <StyleRightArrow {...rest} disabled={isLastSlide} isLastSlide={isLastSlide}>
      <svg
        width="13"
        height="23"
        viewBox="0 0 13 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.375 1.25L11.625 11.5L1.375 21.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyleRightArrow>
  );
};

export { LeftArrow, RightArrow };
