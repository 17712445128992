import React from 'react';
import Footer from '../organisms/Footer';

function BottomPageSection() {
  return (
    <div>
        <Footer/>
    </div>
  )
}

export default BottomPageSection