import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";
const StyleHealineSectionContainer = styled.div`
  position: relative;
  background: linear-gradient(101.68deg, #222222 0%, #372424 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 773px;
  @media only screen and ${devices.mobile} {
    height: 650px;
  }
  @media only screen and ${devices.tablet}{
    height: 715px;
  }
  @media only screen and (min-width: 700px) and (max-width: 769px) {
    height: 800px;
  }
  @media only screen and ${devices.tabletLarge}{
    height: 840px;
  }
  @media only screen and (min-width: 800px) and (max-width: 1024px) {
    height: 940px;
  }
`;

export { StyleHealineSectionContainer };
