import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

interface DotsProps {
  list_index: number;
  current_index: number;
}
interface InnerCarouselProps {
  slide_length: number;
  current_index: number;
}

export const StyleCarouselSectionWrapper = styled.div`
  background: linear-gradient(101.68deg, #222222 0%, #372424 100%);
  height: 975px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

export const CarouselHead = styled.div`
  text-align: center;
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.3rem;
    line-height: 55px;
    color: #ffffff;
    margin: 0px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 30px;
    color: #b08c8c;
    margin: 0px;
  }
`;

export const StyleCarouselWrapper = styled.div`
  width: 1081px;
  height: 466px;
  @media only screen and ${devices.mobile} {
    height: 900px;
    width: 320px;
  }
  @media only screen and ${devices.tablet} {
    height: 900px;
    width: 450px;
  }
  @media only screen and ${devices.tabletLarge} {
    height: 900px;
    width: 600px;
  }
  @media only screen and ${devices.laptop} {
    width: 980px;
    height: 466px;
  }
`;

export const InnerWrapper = styled.div`
  position: relative;
`;

export const StyleCarouselContainer = styled.div`
  border-radius: 35px 0px;
  overflow-x: clip;
`;

export const StyleCarouselInnerContainer = styled.div<InnerCarouselProps>`
  display: flex;
  height: 100%;
  transition: transform ease-out 0.7s;
  width: ${(props) => 1081 * props.slide_length}px;
  transform: translateX(${(props) => -(props.current_index * 1081)}px);

  @media only screen and ${devices.mobile} {
    width: 320px;
    transform: translateX(${(props) => -(props.current_index * 320)}px);
  }


  @media only screen and ${devices.tablet} {
    width: ${(props) => 450 * props.slide_length}px;
    transform: translateX(${(props) => -(props.current_index * 450)}px);
  }
  @media only screen and ${devices.tabletLarge} {
    width: ${(props) => 600 * props.slide_length}px;
    transform: translateX(${(props) => -(props.current_index * 600)}px);
  }
  @media only screen and ${devices.laptop} {
    width: ${(props) => 980 * props.slide_length}px;
    transform: translateX(${(props) => -(props.current_index * 980)}px);
  }
`;

export const StyleCarouselItem = styled.div`
  display: flex;
  gap: 1.5em;
  padding: 2em;
  width: 100%;
  height: 100%;
  background: #ffffff;
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyleCarouselImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const ImageTopContainer = styled.div`
  img {
    width: 468px;
    height: 233px;
    object-fit: cover;
    object-position: center bottom;
    border-radius: 25px;
  }
  @media only screen and ${devices.mobile} {
    img {
      width: 308px;
      height: 171px;
    }
  }
  @media only screen and ${devices.tablet} {
    img {
      width: 308px;
      height: 171px;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    img {
      width: 308px;
      height: 171px;
    }
  }
  @media only screen and ${devices.laptop} {
    img {
      width: 350px;
      height: 189px;
    }
  }
`;

export const ImageBottomContainer = styled.div`
  display: flex;
  gap: 1.5em;
  margin-top: 1em;
  img {
    width: 101px;
    height: 109px;
    object-fit: cover;
    object-position: center center;
    border-radius: 15px;
  }
  @media only screen and ${devices.mobile} {
    gap: 0.7em;
    img {
      width: 64px;
      height: 69px;
    }
  }
  @media only screen and ${devices.tablet} {
    img {
      width: 64px;
      height: 69px;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    img {
      width: 64px;
      height: 69px;
    }
  }
  @media only screen and ${devices.laptop} {
    img {
      width: 80px;
      height: 85px;
    }
  }
`;

export const StyleCarouselTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

export const TextHeadContainer = styled.div`
  h5{
        font-style: normal;
        font-weight: 600;
          font-size: 1.7rem;
          line-height: 29px;
          color: #141414;
          margin: 0;
      }
      p{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem
        line-height: 30px;
        color: #4B3737;
        margin: 0.8em 0 0 0; 

      }
      @media only screen and ${devices.mobile}{
        text-align: center;
        h5{
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 30px;
        }
      }
      @media only screen and ${devices.tablet}{
        text-align: center;
        h5{
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 30px;
        }
      }
      @media only screen and ${devices.tabletLarge}{
        text-align: center;
        h5{
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 30px;
        }
      };
`;

export const TextBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  width: 508px;
  height: 260px;
  background: #f4f4f4;
  border-radius: 15px;
  @media only screen and ${devices.mobile} {
    flex-wrap: nowrap;
    height: 240px;
    width: 308px;
  }
  @media only screen and ${devices.tablet} {
    flex-wrap: nowrap;
    height: 240px;
    width: 308px;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-wrap: nowrap;
    height: 240px;
    width: 308px;
  }
  @media only screen and ${devices.laptop} {
    width: 450px;
  }
`;
export const TextBodyItem = styled.div`
  display: flex;
  align-items: center;
  h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 28px;
          color: #767676;
          margin: 0px;
          &::first-letter{
            text-transform: uppercase;
          }
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 0.7rem
          line-height: 24px;
          color: #141414;
          margin-left: auto;
          @media only screen and ${devices.mobile}{
          margin-left:auto;
        }
          @media only screen and ${devices.tablet}{
          margin-left:auto;
        }
        @media only screen and ${devices.tabletLarge}{
          margin-left:auto;
        }
  };
`;

export const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5em;
  margin: 1em 0;
`;
export const Dots = styled.div<DotsProps>`
  width: 13px;
  height: 13px;
  background: ${({ list_index, current_index }) =>
    list_index === current_index ? "#d42b2b" : "#824D4D"};
  border-radius: 50%;
  cursor: pointer;
`;

export const Button = styled.button`
  background: linear-gradient(92.59deg, #e73636 0.43%, #c92626 97.78%);
  border-radius: 20px;
  width: 389px;
  height: 91px;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 30px;
  cursor: pointer;
  @media only screen and ${devices.mobile} {
    font-size: 1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tablet} {
    font-size: 1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tabletLarge} {
    font-size: 1.1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.laptop} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.large} {
    width: 302px;
    height: 64px;
  }
`;
