import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const NavigationMenuContainer = styled.div`
  width: 780px;
  height: 37px;
  background: linear-gradient(263.19deg, #962727 0%, #451919 99.21%);
  border: 1px solid #bd5d5d;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 1.2em;
  &::before{
    content: " ";
    position: absolute;
    width: 18.5px;
    height: 18.5px;
    background: #bd5d5d;
    transform: rotate(45deg);
    top: -9.25px;
    left: 200px;
    z-index: -1;
    @media only screen and (max-width: 1024px) {
    display: none;
  }
  }
  @media only screen and ${devices.mobile} {
    width: 90%;
    height: 900px;
    position: absolute;
    padding: 1.5em;
    background: #1b1b1b;
    border: none;
    flex-direction: column;
    transition: 1s;
    gap:1.5em;
    right: 0;
    top: 0;
  }
  @media only screen and ${devices.tablet} {
    width: 90%;
    height: 900px;
    position: absolute;
    padding: 1.5em;
    background: #1b1b1b;
    border: none;
    flex-direction: column;
    transition: 0.5s
    gap:1.5em;
    right: 0;
    top: 0;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 90%;
    height: 900px;
    position: absolute;
    padding: 1.5em;
    background: #1b1b1b;
    border: none;
    flex-direction: column;
    transition: 0.5s
    gap:1.5em;
    right: 0;
    top: 0;
  }
  ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
    @media only screen and ${devices.mobile} {
      flex-direction: column;
      text-align: start;
      gap: 1.2em;
    }
    @media only screen and ${devices.tablet} {
      flex-direction: column;
      text-align: start;
      gap: 1.2em;
    }
    @media only screen and ${devices.tabletLarge} {
      flex-direction: column;
      text-align: start;
      gap: 1.2em;
    }
    li {
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 24px;
      @media only screen and ${devices.mobile} {
        font-size: 1rem;
      }
      @media only screen and ${devices.tablet} {
        font-size: 1rem;
      }
      @media only screen and ${devices.tabletLarge} {
        font-size: 1rem;
      }
      a {
        color: #ffffff;
      }
    }
  }
`;

export const NavigationMenuHeader = styled.div`
  display: flex;
  align-items: center;
  width: 75%;
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;

export const NavigationMenuCloseIcon = styled.div`
  margin-left: auto;
  position: relative;
  transform: rotate(225deg);
  cursor: pointer;
  & .close_icon {
    background-color: #ffffff;
    width: 30px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;
