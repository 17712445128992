import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

const SharedStyles = styled.div`
  display: flex;
  align-items: center;
`;

export const StyleFooterNavContainer = styled(SharedStyles)`
  width: 1081px;
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    width: 100%;
    gap: 1em;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column;
    width: 100%;
    gap: 1em;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column;
    width: 90%;
    gap: 1em;
  }
  @media only screen and ${devices.laptop} {
    width: 940px;
  }
`;

export const InnerConatinerLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column;
    width: 100%;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column;
    width: 100%;
  }
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #a46a6a;
    width: 152px;
    @media only screen and ${devices.mobile} {
      font-size: 0.6rem;
      line-height: 22px;
      margin-top: -20px;
      width: 100%;
      text-align: center;
    }
    @media only screen and ${devices.tablet} {
      font-size: 0.6rem;
      line-height: 22px;
      margin-top: -20px;
      width: 100%;
      text-align: center;
    }
    @media only screen and ${devices.tabletLarge} {
      font-size: 0.6rem;
      line-height: 22px;
      margin-top: -20px;
      width: 100%;
      text-align: center;
    }
  }
`;
export const InnerConatinerRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2em;
  @media only screen and ${devices.mobile} {
    gap: 1em;
    margin-left: 0px;
  }
  @media only screen and ${devices.tablet} {
    margin-left: 0px;
  }
  @media only screen and ${devices.tabletLarge} {
    margin-left: 0px;
  }
`;

export const FooterNavigationAddressText = styled.div`
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
  }
  address {
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 24px;
  }
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
`;
export const FooterNavigationContactText = styled.div`
  address {
    font-style: normal;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 24px;
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 17px;
    text-decoration-line: underline;
  }
  @media only screen and ${devices.mobile} {
    address {
      font-size: 1.1rem;
    }
  }
  @media only screen and ${devices.tablet} {
    address {
      font-size: 1.2rem;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    address {
      font-size: 1.2rem;
    }
  }
`;

export const FooterNavigationRequestCall = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.4em;
  cursor: pointer;
`;
export const FooterNavigationGreenEllipse = styled.div`
  width: 4px;
  height: 4px;
  background: #60f166;
  border-radius: 50%;
`;
export const StyleFooterNavAddress = styled(SharedStyles)`
  svg {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  gap: 1.3em;
`;
export const StyleFooterNavSocialLogo = styled(SharedStyles)`
  gap: 1em;
  a {
    svg {
      width: 42px;
      height: 42px;
    }
  }
  @media only screen and ${devices.mobile} {
    gap: 0.5em;
    a {
      svg {
        width: 42px;
        height: 42px;
      }
    }
  }
  @media only screen and ${devices.tablet} {
    a {
      svg {
        width: 42px;
        height: 42px;
      }
    }
  }
  @media only screen and ${devices.tabletLarge} {
    a {
      svg {
        width: 42px;
        height: 42px;
      }
    }
  }
`;

export const StyleFooterNavContact = styled(SharedStyles)`
  gap: 1.3em;
  text-align: end;
  @media only screen and ${devices.mobile} {
    gap: 0.5em;
  }
`;
