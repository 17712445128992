import React, { useState } from "react";
import { scroller } from "react-scroll";
import {
  StyleCarouselSectionWrapper,
  StyleCarouselItem,
  CarouselHead,
  StyleCarouselWrapper,
  InnerWrapper,
  StyleCarouselInnerContainer,
  StyleCarouselContainer,
  StyleCarouselImageContainer,
  ImageTopContainer,
  ImageBottomContainer,
  StyleCarouselTextContainer,
  TextHeadContainer,
  TextBodyContainer,
  TextBodyItem,
  DotContainer,
  Dots,
  Button,
} from "./styles/StyleCarouselSection";
import { succeessfulHistoryListData } from "./carouselSectionData";
import useWindowSize from "../../hooks/useWindowSize";
import { LeftArrow, RightArrow } from "../atoms/buttons/ArrowButton";
import {
  StyleCarouselLeftSideShades,
  StyleCarouselRightSideShades,
} from "../molecules/ShadowEffects/StyleShadowsEffects";

function CarouselSection() {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [touchPosition, setTouchPosition] = useState<null | number>(null);
  const { windowWidth } = useWindowSize();
  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === succeessfulHistoryListData.length - 1;

  const goToPrevious = () => {
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const goToNext = () => {
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  const goToSlide = (slideIndex: number) => {
    setCurrentIndex(slideIndex);
  };
  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };
  const handleTouchMove = (e: React.TouchEvent) => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diffrence = touchDown - currentTouch;
    if (diffrence > 5 && !isLastSlide) {
      goToNext();
    }
    if (diffrence < -5 && !isFirstSlide) {
      goToPrevious();
    }
    setTouchPosition(null);
  };
  const handleScrollToForm = () => {
    scroller.scrollTo("form_section", {
      duration: 1500,
      deelay: 100,
      smooth: true,
      offset: -50,
    });
  };

  return (
    <StyleCarouselSectionWrapper id="successful_cases">
      <StyleCarouselLeftSideShades />
      <StyleCarouselRightSideShades />

      <CarouselHead>
        <h4>Наши успешные кейсы</h4>
        <p>Мы помогли купить авто уже 957+ клиентам</p>
      </CarouselHead>

      <StyleCarouselWrapper>
        <InnerWrapper>
          <LeftArrow onClick={goToPrevious} isFirstSlide={isFirstSlide} />
          <RightArrow onClick={goToNext} isLastSlide={isLastSlide} />
          <StyleCarouselContainer>
            <StyleCarouselInnerContainer
              slide_length={succeessfulHistoryListData.length}
              current_index={currentIndex}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
            >
              {succeessfulHistoryListData.map((list) => (
                <StyleCarouselItem key={list.id}>
                  <StyleCarouselImageContainer>
                    <ImageTopContainer>
                      <img loading="lazy" src={list.images.img1} alt="" />
                    </ImageTopContainer>

                    <ImageBottomContainer>
                      <img loading="lazy" src={list.images.img2} alt="" />
                      <img loading="lazy" src={list.images.img3} alt="" />
                      <img loading="lazy" src={list.images.img4} alt="" />
                      <img loading="lazy" src={list.images.img5} alt="" />
                    </ImageBottomContainer>
                  </StyleCarouselImageContainer>

                  <StyleCarouselTextContainer>
                    <TextHeadContainer>
                      <h5>{list.car_name}</h5>
                      <p>Дата выкупа: {list.date_of_redemption}</p>
                    </TextHeadContainer>

                    <TextBodyContainer>
                      <TextBodyItem>
                        <h6>год выпуска</h6>
                        <span>{list.car_history.year_of_manufacture}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>объем</h6>
                        <span>{list.car_history.volume}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>мощность</h6>
                        <span>{list.car_history.power}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>коробка передач</h6>
                        <span>{list.car_history.gear_box}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>привод</h6>
                        <span>{list.car_history.drive_unit}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>владельцев</h6>
                        <span>{list.car_history.owners}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>пробег</h6>
                        <span>{list.car_history.mileage}</span>
                      </TextBodyItem>

                      <TextBodyItem>
                        <h6>выкуп</h6>
                        <span>{list.car_history.redemption}</span>
                      </TextBodyItem>
                    </TextBodyContainer>
                  </StyleCarouselTextContainer>
                  {windowWidth < 1024 && (
                    <>
                      <DotContainer>
                        {succeessfulHistoryListData.map((list, listIndex) => (
                          <Dots
                            onClick={() => goToSlide(listIndex)}
                            list_index={listIndex}
                            current_index={currentIndex}
                            key={listIndex}
                          />
                        ))}
                      </DotContainer>

                      <Button onClick={handleScrollToForm}>
                        Получить оценку вашего авто
                      </Button>
                    </>
                  )}
                </StyleCarouselItem>
              ))}
            </StyleCarouselInnerContainer>
          </StyleCarouselContainer>
        </InnerWrapper>
      </StyleCarouselWrapper>

      {windowWidth > 1024 && (
        <DotContainer>
          {succeessfulHistoryListData.map((list, listIndex) => (
            <Dots
              onClick={() => goToSlide(listIndex)}
              list_index={listIndex}
              current_index={currentIndex}
              key={listIndex}
            />
          ))}
        </DotContainer>
      )}
      {windowWidth > 1024 && (
        <Button onClick={handleScrollToForm}>
          Получить оценку вашего авто
        </Button>
      )}
    </StyleCarouselSectionWrapper>
  );
}

export default CarouselSection;
