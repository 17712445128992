import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const SubHeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 1171px;
  height: 385px;
  position: absolute;
  left: 280px;
  top: 650px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  box-shadow: 0px 4px 35px rgba(59, 88, 124, 0.15);
  z-index: 10;
  & .subheadline_arrow_up {
    position: absolute;
    width: 68px;
    height: 34px;
    left: 120px;
    top: -20px;
    img {
      width: 100%;
    }
    @media only screen and ${devices.mobile} {
      left: 50px;
    }
    @media only screen and ${devices.tablet} {
      left: 50px;
    }
  }
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    width: 90%;
    height: auto;
    left: 23px;
    top: 610px;
  }
  @media only screen and (min-width: 450px) and (max-width: 481px) {
    top: 625px;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column;
    width: 90%;
    height: auto;
    left: 23px;
    top: 650px;
  }
  @media only screen and (min-width: 510px) and (max-width: 570px) {
    top: 670px;
  }
  @media only screen and (min-width: 571px) and (max-width: 699px) {
    top: 680px;
  }
  @media only screen and (min-width: 700px) and (max-width: 769px) {
    top: 750px;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    left: 33px;
    top: 780px;
  }
  @media only screen and (min-width: 810px) and (max-width: 899px) {
    top: 810px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1024px) {
    top: 860px;
  }
  @media only screen and ${devices.laptop} {
    width: 900px;
    left: 70px;
  }
  @media only screen and ${devices.large} {
    width: 1000px;
    /* left: 200px; */
    left: 140px;
  }
`;

export const SubHeadlineArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 279px;
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 36px;
    color: #141414;
    margin: 0px;
    span {
      color: #e73636;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 30px;
    color: #4b3737;
    margin: 0px;
  }
  @media only screen and ${devices.mobile} {
    margin-top: -2.5em;
    justify-content: center;
    gap: 1em;
    h4 {
      font-size: 1.2rem;
      line-height: 30px;
    }
    p {
      font-size: 0.8rem;
      line-height: 24px;
    }
    button {
      display: none;
    }
  }
  @media only screen and ${devices.tablet} {
    margin-top: -2.5em;
    justify-content: center;
    gap: 1em;
    h4 {
      font-size: 1.2rem;
      line-height: 30px;
    }
    p {
      font-size: 0.8rem;
      line-height: 24px;
    }
    button {
      display: none;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    margin-top: -2.5em;
    justify-content: center;
    gap: 1em;
    h4 {
      font-size: 1.2rem;
      line-height: 30px;
    }
    p {
      font-size: 0.8rem;
      line-height: 24px;
    }
    button {
      display: none;
    }
  }
`;

export const SubHeadlineMedia = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 272px;
  height: 279px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 25px rgba(225, 231, 238, 0.45);
  border-radius: 15px;
  position: relative;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 26px;
    text-align: center;
    color: #141414;
  }
  @media only screen and ${devices.mobile} {
    margin: 1em;
    p {
      font-size: 0.8rem;
      width: 202px;
      line-height: 24px;
    }
  }
  @media only screen and ${devices.tablet} {
    margin: 1em;
    p {
      font-size: 0.8rem;
      width: 202px;
      line-height: 24px;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    margin: 1em;
    p {
      font-size: 0.8rem;
      width: 202px;
      line-height: 24px;
    }
  }
`;

export const SubHeadlineImageContainer = styled.div`
  & .left_verified_logo,
  & .right_verified_logo {
    position: absolute;
    width: 80px;
    height: 80px;
  }
  & .left_verified_logo {
    left: 30px;
    top: 40px;
  }
  & .right_verified_logo {
    left: 140px;
    top: 33px;
  }
  & .telephone_logo {
    width: 159px;
    height: 140px;
  }
  & .note_logo {
    width: 205px;
    height: 149px;
  }
`;

export const SubHeadlineButton = styled.button`
  background: linear-gradient(92.59deg, #e73636 0.43%, #c92626 97.78%);
  border-radius: 20px;
  width: 389px;
  height: 91px;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 30px;
  cursor: pointer;
  @media only screen and ${devices.mobile} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tablet} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tabletLarge} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.laptop} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.large} {
    width: 302px;
    height: 64px;
  }
`;
