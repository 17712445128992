import React, { useState, useEffect, useRef } from "react";
import Navigation from "../molecules/Navigation";
import StyleHeaderContainer from "./styles/StyleHeader";
import NavigationMenu from "../molecules/NavigationMenu";
import useWindowSize from "../../hooks/useWindowSize";
function Header() {
  const [openNavMenu, setOpenNaveMenu] = useState<boolean>(false);
  const { windowWidth } = useWindowSize();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const menuListRef = useRef<HTMLDivElement | null>(null);

  const handleOpenNavMenu = () => {
    if (windowWidth > 1024) {
      setOpenNaveMenu((prvState) => !prvState);
    } else {
      setOpenNaveMenu(true);
    }
  };

  const handleCloseNavMenu = () => {
    setOpenNaveMenu(false);
  };

  useEffect(() => {
    const handler = (e?: any) => {
      if (
        !menuRef.current?.contains(e.target) &&
        !menuListRef.current?.contains(e.target)
      ) {
        setOpenNaveMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, []);

  return (
    <StyleHeaderContainer>
      <Navigation handleOpenNavMenu={handleOpenNavMenu} menuRef={menuRef} />
      {windowWidth < 1024 && openNavMenu && (
        <NavigationMenu
          handleCloseNavMenu={handleCloseNavMenu}
          menuListRef={menuListRef}
        />
      )}
      {windowWidth > 1024 && openNavMenu && (
        <NavigationMenu
          handleCloseNavMenu={handleCloseNavMenu}
          menuListRef={menuListRef}
        />
      )}
    </StyleHeaderContainer>
  );
}

export default Header;
