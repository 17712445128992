import React from "react";
import {
  MainWrapper,
  MainFirst,
  MainFirstFlex,
  MainFirstTitle,
  MainSecond,
  MainSecondTitle,
  MainSecondGrid,
  MiniSliderContainer
} from "./styles/StyleMainSection";
import {
  cooperationListData,
  reasonToChooseOurCompanyListData,
} from "./mainSectionData";

import MiniSlider from "../organisms/MiniSlider";
import useWindowSize from "../../hooks/useWindowSize";

function MainSection() {
  const { windowWidth } = useWindowSize();
  return (
    <MainWrapper>

      <MainFirst id="stage_work">
        <MainFirstTitle>
          <h3>
            Как происходит <span>выкуп?</span>
          </h3>
          <p>Вы продадите автомобиль всего за 5 простых шагов</p>
        </MainFirstTitle>

        {windowWidth < 1024 ? (
          <MiniSliderContainer>
            <MiniSlider slides={cooperationListData} />
          </MiniSliderContainer>
        ) : (
          <MainFirstFlex>
            {cooperationListData.map((list) => (
              <div className="main_first_flex_item" key={list.id}>
                <div className="flex_item_logo_container">
                  <img src={list.img} alt={list.title} />
                </div>
                <h5>{list.title}</h5>
                <p>{list.text}</p>
              </div>
            ))}
          </MainFirstFlex>
        )}
      </MainFirst>

      <MainSecond id="our_advantages">
        <MainSecondTitle>
          <h3>
            Почему выбирают <span>нашу компанию?</span>
          </h3>
          <p>За все время работы мы успели провести 700+ успешных сделок</p>
        </MainSecondTitle>

        <MainSecondGrid>
          {reasonToChooseOurCompanyListData.map((list) => (
            <div className="main_second_grid_item" key={list.id}>
              <img src={list.img} alt={list.title} />
              <h5>{list.title}</h5>
              <p>{list.text}</p>
            </div>
          ))}
        </MainSecondGrid>
      </MainSecond>
    </MainWrapper>
  );
}

export default MainSection;
