import styled from "styled-components";
import { devices } from "../breakPointView";

interface Props {
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
}

export const SharedArrowStyles = styled.button<Props>`
  width: 73px;
  height: 73px;
  background: linear-gradient(180deg, #e73636 0%, #b31616 100%);
  transform: rotate(-180deg);
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  z-index: 1;
  @media only screen and ${devices.mobile} {
    width: 32px;
    height: 32px;
  }
  @media only screen and ${devices.tablet} {
    width: 32px;
    height: 32px;
  }
`;

export const StyleLeftArrow = styled(SharedArrowStyles)`
  background: ${(props) => (props.isFirstSlide ? "#824D4D" : "")};
  left: -45px;
  @media only screen and ${devices.mobile} {
    left: -23px;
  }
  @media only screen and ${devices.tablet} {
    left: -23px;
  }
`;

export const StyleRightArrow = styled(SharedArrowStyles)`
  background: ${(props) => (props.isLastSlide ? "#824D4D" : "")};
  right: -45px;
  @media only screen and ${devices.mobile} {
    right: -23px;
  }
  @media only screen and ${devices.tablet} {
    right: -23px;
  }
`;
