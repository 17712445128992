import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const HeadlineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media only screen and ${devices.mobile} {
    flex-direction: column;
    margin-top: 2.5em;
    margin-left: 0px;
  }
  @media only screen and ${devices.tablet} {
    flex-direction: column;
    margin-top: 2.5em;
    margin-left: 0px;
  }
  @media only screen and ${devices.tabletLarge} {
    flex-direction: column;
    margin-top: 2.5em;
    margin-left: 0px;
  }
`;

export const HeadlineLeftSide = styled.div`
  & .headline_topic {
    width: 467px;
    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 40px;
      color: white;
      span {
        font-weight: 500;
      }
    }
    @media only screen and ${devices.mobile} {
      width: 276px;
      h2 {
        font-size: 1.4rem;
        line-height: 32px;
        text-align: center;
      }
    }
    @media only screen and ${devices.tablet} {
      width: 276px;
      h2 {
        font-size: 1.4rem;
        line-height: 32px;
        text-align: center;
      }
    }
    @media only screen and ${devices.tabletLarge} {
      width: 276px;
      h2 {
        font-size: 1.4rem;
        line-height: 32px;
        text-align: center;
      }
    }
    @media only screen and ${devices.laptop} {
      width: 300px;
      h2 {
        font-size: 1.5rem;
      }
    }
  }

  & .headline_support_copy {
    display: flex;
    gap: 1em;
    width: 400px;
    svg {
      width: 65px;
      height: 70px;
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 25px;
      color: #b08c8c;
      margin: 0px;
    }
    @media only screen and ${devices.mobile} {
      width: 292px;
      svg {
        display: none;
      }
      h4 {
        font-size: 0.8rem;
        line-height: 24px;
        text-align: center;
      }
    }
    @media only screen and ${devices.tablet} {
      width: 292px;
      svg {
        display: none;
      }
      h4 {
        font-size: 0.8rem;
        line-height: 24px;
        text-align: center;
      }
    }
    @media only screen and ${devices.tabletLarge} {
      width: 292px;
      svg {
        display: none;
      }
      h4 {
        font-size: 0.8rem;
        line-height: 24px;
        text-align: center;
      }
    }
    @media only screen and ${devices.laptop} {
      width: 350px;
      h4 {
        font-size: 0.9rem;
      }
    }
  }
  button {
    margin-top: 0.5em;
  }
`;

export const HeadlineImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 590px;
  width: 600px;
  z-index: 1;
  position: relative;
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  @media only screen and ${devices.mobile} {
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
  @media only screen and ${devices.tablet} {
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    width: 100%;
    height: auto;
    img {
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 1025px) and (max-width: 1201px) {
    height: 530px;
    width: 520px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media only screen and (min-width: 1201px) and (max-width: 1301px) {
    height: 530px;
    width: 470px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media only screen and (min-width: 1301px) and (max-width: 1400px) {
    height: 530px;
    width: 500px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media only screen and (min-width: 1400px) and (max-width: 1500px) {
    height: 530px;
    width: 570px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media only screen and (min-width: 1560px) and (max-width: 1650px) {
    height: 530px;
    width: 650px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  @media only screen and (min-width: 1650px) {
    height: 530px;
    width: 710px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const HeadlineRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  transform: rotate(90deg);
  margin: 0px;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    color: #b08c8c;
    margin: 0px;
  }
  & .arrow_down_wrapper {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(180deg, #c52b2b 0%, #a41717 100%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  @media only screen and ${devices.mobile} {
    display: none;
  }
  @media only screen and ${devices.tablet} {
    display: none;
  }
  @media only screen and ${devices.tabletLarge} {
    display: none;
  }
`;

export const HeadlineButton = styled.button`
  background: linear-gradient(92.59deg, #e73636 0.43%, #c92626 97.78%);
  border-radius: 20px;
  width: 389px;
  height: 91px;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 30px;
  cursor: pointer;
  @media only screen and ${devices.mobile} {
    font-size: 1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tablet} {
    font-size: 1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.tabletLarge} {
    font-size: 1.1rem;
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.laptop} {
    width: 302px;
    height: 64px;
  }
  @media only screen and ${devices.large} {
    width: 302px;
    height: 64px;
  }
`;
