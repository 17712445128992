
function LocationIcon() {
  return (
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0039 14C11.7602 14 12.4078 13.7256 12.9468 13.1768C13.4858 12.628 13.7548 11.9691 13.7539 11.2C13.7539 10.43 13.4844 9.7706 12.9454 9.2218C12.4064 8.673 11.7592 8.39907 11.0039 8.4C10.2477 8.4 9.60003 8.6744 9.06103 9.2232C8.52203 9.772 8.25299 10.4309 8.25391 11.2C8.25391 11.97 8.52341 12.6294 9.06241 13.1782C9.60141 13.727 10.2486 14.0009 11.0039 14ZM11.0039 28C7.31432 24.8033 4.55882 21.8344 2.73741 19.0932C0.91599 16.352 0.00482292 13.8143 0.00390625 11.48C0.00390625 7.98 1.10986 5.19167 3.32178 3.115C5.5337 1.03833 8.09441 0 11.0039 0C13.9143 0 16.4755 1.03833 18.6874 3.115C20.8993 5.19167 22.0048 7.98 22.0039 11.48C22.0039 13.8133 21.0927 16.3511 19.2704 19.0932C17.4481 21.8353 14.6926 24.8043 11.0039 28Z"
        fill="#C83E3E"
      />
    </svg>
  );
}

export default LocationIcon;
