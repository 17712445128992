import styled from "styled-components";
import { devices } from "../../atoms/breakPointView";

export const MainWrapper = styled.div`
  margin-top: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media only screen and ${devices.mobile} {
    margin-top: 880px;
  }
  @media only screen and ${devices.tablet} {
    margin-top: 880px;
  }
  @media only screen and ${devices.tabletLarge} {
    margin-top: 850px;
  }
`;

export const MainFirst = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  @media only screen and ${devices.mobile} {
    align-items: center;
    gap: 2.5em;
  }
  @media only screen and ${devices.tablet} {
    align-items: center;
    gap: 2.5em;
  }
  @media only screen and ${devices.tabletLarge} {
    align-items: center;
    gap: 2.5em;
  }
`;

export const MainFirstTitle = styled.div`
  text-align: center;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 2.1rem;
    line-height: 30px;
    color: #141414;
    span {
      color: #ca2828;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 22px;
    color: #4b3737;
    margin: 0px;
  }
  @media only screen and ${devices.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 1.5rem;
      width: 300px;
    }
    p {
      font-size: 1rem;
      width: 234px;
    }
  }
  @media only screen and ${devices.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 1.5rem;
      width: 300px;
    }
    p {
      font-size: 1rem;
      width: 234px;
    }
  }
  @media only screen and ${devices.tabletLarge} {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 1.5rem;
      width: 300px;
    }
    p {
      font-size: 1rem;
      width: 234px;
    }
  }
`;

export const MainFirstFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1100px;
  @media only screen and ${devices.laptop} {
    width: 980px;
  }
  & .main_first_flex_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.3em;
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 26px;
      color: #141414;
      margin: 0px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 24px;
      text-align: center;
      color: #4b3737;
      width: 182px;
      height: 96px;
      margin: 0px;
    }
    & .flex_item_logo_container {
      width: 90px;
      height: 90px;
      background: #ca2828;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MainSecond = styled.div`
  margin-top: 100px;
  background: #fbfbfb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5em;
  padding: 1em 0 3em 0;
`;

export const MainSecondTitle = styled(MainFirstTitle)``;

export const MainSecondGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 367px);
  gap: 1.5em;
  @media only screen and ${devices.mobile} {
    display: flex;
    flex-direction: column;
  }
  @media only screen and ${devices.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media only screen and ${devices.tabletLarge} {
    display: flex;
    flex-direction: column;
  }
  @media only screen and ${devices.laptop} {
    grid-template-columns: repeat(3, 290px);
  }
  & .main_second_grid_item {
    img {
      margin: 0 0 0 0.5em;
    }
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 24px;
      color: #141414;
      margin: 0;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 24px;
      color: #4b3737;
    }
    border: 2px solid #ca2828;
    border-radius: 36px 0px;
    height: 194px;
    padding: 1em;
  }
  & .main_second_grid_item:nth-child(even) {
    border: 2px solid #faecec;
  }
  @media only screen and ${devices.mobile} {
    width: 80%;
  }export 
  @media only screen and ${devices.tablet} {
    width: 80%;
  }
`;

export const MiniSliderContainer = styled.div`
  width: 450px;
  height: 300px;
  @media only screen and (max-width: 480px) {
    width: 340px;
  }
  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    width: 550px;
  }
`;
