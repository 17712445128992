import React from "react";
import MenuList from "../atoms/menuList/MenuList";
import {
  NavigationMenuContainer,
  NavigationMenuHeader,
  NavigationMenuCloseIcon,
} from "./styles/StyleNavigationMenu";
import CompanyLogoBlack from "../atoms/icons/CompanyLogoBlack";

type NavigationMenuProps = {
  handleCloseNavMenu?: () => void;
  menuListRef?: React.MutableRefObject<HTMLDivElement | null>;
};

function NavigationMenu({ handleCloseNavMenu,menuListRef}: NavigationMenuProps) {
  return (
    <NavigationMenuContainer ref={menuListRef} >
      <NavigationMenuHeader>
        <CompanyLogoBlack />
        <NavigationMenuCloseIcon onClick={handleCloseNavMenu}>
          <div className="close_icon"></div>
          <div className="close_icon"></div>
        </NavigationMenuCloseIcon>
      </NavigationMenuHeader>
      <MenuList handleCloseMenu={handleCloseNavMenu} />
    </NavigationMenuContainer>
  );
}

export default NavigationMenu;
