import styled from "styled-components";

const MenuListStyle = styled.ul`
  li {
    list-style: none;
  }
  span {
    color: #ffffff;
    cursor: pointer;
  }
`;

export default MenuListStyle;
