import React from "react";

function ViberIcon() {
  return (
    <a
      href="viber://chat/?number=%2B79260002601"
      target="_blank"
      rel="noreferrer"
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0Z"
          fill="#7360F2"
        />
        <path
          d="M34.0651 12.7316C33.3839 12.0896 30.6308 10.0486 24.4985 10.0208C24.4985 10.0208 17.2669 9.57547 13.7416 12.8778C11.7792 14.8824 11.0889 17.8154 11.0161 21.4518C10.9433 25.0882 10.8491 31.903 17.2813 33.7509H17.2875L17.2833 36.5705C17.2833 36.5705 17.2422 37.712 17.9782 37.9448C18.8685 38.2271 19.3909 37.3595 20.2409 36.4243C20.7074 35.9108 21.3517 35.1567 21.8371 34.5802C26.2362 34.9584 29.6196 34.0941 30.0038 33.9664C30.892 33.6723 35.9181 33.0143 36.7355 26.2004C37.5793 19.1767 36.3274 14.7341 34.0651 12.7316ZM34.8106 25.6966C34.1207 31.3863 30.0441 31.7438 29.2925 31.99C28.9728 32.095 26.0013 32.8501 22.2646 32.6009C22.2646 32.6009 19.4802 36.0314 18.6105 36.9234C18.4748 37.0629 18.3151 37.1192 18.2086 37.0915C18.0588 37.0541 18.0177 36.873 18.0193 36.6083C18.0218 36.2302 18.0432 31.9228 18.0432 31.9228C18.0399 31.9228 18.0432 31.9228 18.0432 31.9228C12.602 30.38 12.9192 24.5798 12.9809 21.5426C13.0426 18.5053 13.6013 16.0172 15.2609 14.3437C18.2427 11.5855 24.3853 11.9976 24.3853 11.9976C29.5727 12.0207 32.0583 13.616 32.6347 14.1505C34.5485 15.8239 35.5235 19.8284 34.8106 25.6949V25.6966Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.604 19.0854C24.614 18.89 24.7771 18.7399 24.9684 18.7501C25.684 18.7883 26.2794 19.0095 26.7035 19.4573C27.1259 19.9033 27.333 20.5265 27.3694 21.2748C27.3788 21.4702 27.2315 21.6365 27.0402 21.6461C26.8488 21.6558 26.6861 21.5053 26.6766 21.3099C26.6455 20.6693 26.4734 20.2332 26.2049 19.9497C25.9381 19.668 25.5309 19.4894 24.9323 19.4575C24.741 19.4473 24.594 19.2807 24.604 19.0854Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.0791 17.1772C24.093 16.9821 24.2592 16.8356 24.4503 16.8498C25.9067 16.9587 27.0789 17.4669 27.9328 18.4188L27.9329 18.4189C28.7775 19.3615 29.1789 20.534 29.1498 21.9093C29.1456 22.1048 28.987 22.2599 28.7955 22.2557C28.6041 22.2515 28.4522 22.0895 28.4563 21.8939C28.482 20.6811 28.1328 19.6913 27.4212 18.8972C26.7118 18.1064 25.7201 17.655 24.3996 17.5563C24.2086 17.542 24.0651 17.3723 24.0791 17.1772Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4668 15.3632C23.4681 15.1676 23.6245 15.0101 23.816 15.0115C25.8287 15.0258 27.5299 15.7101 28.8958 17.0699C30.2731 18.4412 30.9543 20.308 30.9715 22.6265C30.973 22.8221 30.8189 22.9819 30.6274 22.9834C30.4358 22.9849 30.2794 22.8275 30.2779 22.6319C30.2617 20.4593 29.6289 18.789 28.4116 17.5772C27.183 16.354 25.6575 15.733 23.8112 15.7198C23.6197 15.7185 23.4655 15.5588 23.4668 15.3632Z"
          fill="white"
        />
        <path
          d="M25.0194 26.0363C25.0194 26.0363 25.5069 26.0783 25.7694 25.7481L26.2812 25.0905C26.528 24.7645 27.1237 24.5565 27.7071 24.8884C28.1443 25.1431 28.5687 25.4199 28.9787 25.7178C29.3659 26.0086 30.1586 26.6842 30.1611 26.6842C30.5388 27.0098 30.626 27.4879 30.3689 27.9921C30.3689 27.995 30.3668 28.0001 30.3668 28.0026C30.0836 28.504 29.7241 28.9561 29.3017 29.342C29.2968 29.3445 29.2968 29.3471 29.2922 29.3496C28.9253 29.6627 28.5647 29.8407 28.2107 29.8836C28.1585 29.8929 28.1055 29.8963 28.0527 29.8937C27.8965 29.8952 27.7412 29.8708 27.5927 29.8214L27.5812 29.8042C27.0357 29.6471 26.1249 29.2538 24.608 28.3992C23.7303 27.9105 22.8946 27.3467 22.11 26.714C21.7168 26.397 21.3416 26.0573 20.9865 25.6964L20.9486 25.6577L20.9108 25.6191L20.873 25.5804C20.8602 25.5678 20.8479 25.5548 20.8351 25.5418C20.4817 25.1791 20.1491 24.796 19.8387 24.3944C19.2192 23.5932 18.6672 22.7399 18.1886 21.8436C17.3518 20.2941 16.9667 19.3647 16.8129 18.8068L16.796 18.795C16.7478 18.6434 16.724 18.4847 16.7256 18.3253C16.7228 18.2713 16.7259 18.2172 16.7351 18.164C16.7793 17.8029 16.9538 17.4344 17.2588 17.0585C17.2613 17.0539 17.2637 17.0539 17.2662 17.0489C17.644 16.6175 18.0867 16.2505 18.5778 15.9615C18.5802 15.9615 18.5852 15.959 18.588 15.959C19.0817 15.6964 19.5499 15.7855 19.8683 16.1691C19.8708 16.1716 20.5311 16.9812 20.8145 17.3766C21.1063 17.7958 21.3774 18.2297 21.6266 18.6765C21.9516 19.2719 21.748 19.8815 21.4288 20.1328L20.7849 20.6554C20.4599 20.9235 20.5027 21.4214 20.5027 21.4214C20.5027 21.4214 21.4563 25.1073 25.0194 26.0363Z"
          fill="white"
        />
      </svg>
    </a>
  );
}

export default ViberIcon;
